import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Filter from './Filter';
import config from '../../config';

const Filters = props => {
  const { categories, disabled, filters, isLargeUp, map, navbarHeight, setFilters } = props;

  if (disabled) return null;

  return (
    <>
      <Box sx={{
        ...(navbarHeight === config.defaultNavbarHeight && isLargeUp && {
          // borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
          // height: 'calc(100% - 1px)'
        }),
        overflowX: 'scroll'
      }}>
        <Stack direction='column' spacing={0}>
          {Object.entries(categories).map(([name, options]) => {
            return (
              <Filter
                key={name}
                name={name}
                options={options}
                setFilters={setFilters}
                filters={filters}
                isLargeUp={isLargeUp}
                map={map}
              />
            );
          })}
        </Stack>
      </Box>
    </>
  );
};

export default Filters;
