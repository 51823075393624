import FilterListIcon from '@mui/icons-material/FilterList';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useTheme } from '@mui/material/styles';
import Button from '../Button/Button';

const getIcon = name => {
  if (name === 'list') return FormatListBulletedIcon;
  if (name === 'map') return LocationOnIcon;
  if (name === 'filters') return FilterListIcon;
};

const MobileNavTab = props => {
  const theme = useTheme();
  const Icon = getIcon(props.name);

  return (
    <Button
      toggle
      value={props.name}
      onClick={() => props.setView(props.name)}
      borderRadius={false}
      {...props}
      sx={{
        color: '#ffffff'
      }}
    >
      <Icon
        sx={{
          color: props.view === props.name ? theme.palette.primary.main : '',
          marginRight: '0.5rem'
        }}
      />
      {props.children}
    </Button>
  );
};

export default MobileNavTab;
