const setSearchQuery = set => objSpread => set(state => {
  return {
    ...state,
    searchQuery: {
      ...state.searchQuery,
      ...objSpread
    }
  };
}, false, 'SET_SEARCH_QUERY');

export default setSearchQuery;
