/* eslint-disable no-useless-escape */
const readBoolean = (variable, defaultValue) => {
  if (variable === 'true') return true;
  if (variable === 'false') return false;
  return defaultValue;
};

const formGroups = {
  contactForm: [
    {
      autoFocus: true,
      helperText: 'Ange förnamn och efternamn, separerade med ett mellanslag',
      label: 'För- och efternamn',
      name: 'name',
      regex: /^(.+){1}\s(.+){1}$/,
      required: true,
      type: 'text'
    },
    {
      helperText: 'Ange en giltig e-postadress',
      label: 'E-postadress',
      name: 'email',
      regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      required: true,
      type: 'email'
    },
    {
      helperText: 'Ditt meddelande måste vara minst 10 tecken långt',
      label: 'Meddelande',
      minRows: 5,
      multiline: true,
      name: 'message',
      regex: /(.+){10}/,
      required: true,
      type: 'text'
    }
  ]
};

const defaultState = {
  currentModal: null,
  formReceiver: '',
  searchQuery: {
    name: '',
    place: '',
    placeholder: ''
  },
  selectedSpot: null,
  spots: []
};

const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  appFooter: process.env.REACT_APP_FOOTER_TEXT && {
    text: process.env.REACT_APP_FOOTER_TEXT,
    urls: {
      1: {
        text: process.env.REACT_APP_FOOTER_URL_1_TEXT,
        url: process.env.REACT_APP_FOOTER_URL_1
      },
      2: {
        text: process.env.REACT_APP_FOOTER_URL_2_TEXT,
        url: process.env.REACT_APP_FOOTER_URL_2
      }
    }
  },
  colors: {
    primary: process.env.REACT_APP_PRIMARY_COLOR || '#000000',
    secondary: process.env.REACT_APP_SECONDARY_COLOR || '#ffffff'
  },
  debug: readBoolean(process.env.REACT_APP_DEBUG),
  defaultNavbarHeight: 50,
  defaultState,
  disconnectedPlaceSearch: readBoolean(process.env.REACT_APP_DISCONNECTED_PLACE_SEARCH),
  formGroups,
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  googleMapsDefaultCenter: {
    lat: 59.33459,
    lng: 18.06324
  },
  hideSpotEmail: readBoolean(process.env.REACT_APP_HIDE_SPOT_EMAIL),
  multispotProfilePage: readBoolean(process.env.REACT_APP_MULTISPOT_PROFILE_PAGE),
  silverMarkerDefault: readBoolean(process.env.REACT_APP_SILVER_MARKER_DEFAULT),
  spotsOrderBy: process.env.REACT_APP_SPOTS_ORDER_BY || 'DISTANCE',
  tierDependentSpotData: readBoolean(process.env.REACT_APP_TIER_DEPENDENT_SPOT_DATA),
  useContactForm: readBoolean(process.env.REACT_APP_USE_CONTACT_FORM),
  useNameSearch: readBoolean(process.env.REACT_APP_USE_NAME_SEARCH),
  useStyleHack: readBoolean(process.env.REACT_APP_USE_STYLE_HACK),
  viewportLarge: (process.env.REACT_APP_VIEWPORT_LARGE && Number(process.env.REACT_APP_VIEWPORT_LARGE)) || 1400,
  zoomOutForInriktning: readBoolean(process.env.REACT_APP_ZOOM_OUT_FOR_INRIKTNING)
};

export default config;
