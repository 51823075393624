import { Box, Typography } from '@mui/material';
import ListItem from './ListItem';
import config from '../../config';
import { getSpotsWithDistance, orderSpotsByDistance, orderSpotsByName, orderSpotsByTierDistance } from '../../utils';

const orderSpots = (spots, geolocation) => {
  const spotsWithDistance = getSpotsWithDistance(spots, geolocation);

  if (config.spotsOrderBy === 'DISTANCE') {
    return orderSpotsByDistance(spotsWithDistance);
  };

  if (config.spotsOrderBy === 'NAME') {
    return orderSpotsByName(spotsWithDistance);
  };

  if (config.spotsOrderBy === 'TIER+DISTANCE') {
    return orderSpotsByTierDistance(spotsWithDistance);
  };
};

const List = props => {
  const { geolocation, hideMap, setCenter, spots } = props;

  if (!window?.google?.maps && !hideMap) return null;

  const orderedSpots = orderSpots(spots, geolocation);

  if (orderedSpots.length === 0) {
    return (
      <div>
        <Box sx={{ marginTop: '1rem' }}>
          <Typography variant="h6">
            Inga resultat
          </Typography>
        </Box>
      </div >
    );
  }

  return (
    <div>
      {orderedSpots.map(spot => {
        return (
          <ListItem
            key={spot.id}
            id={spot.id}
            spot={spot}
            setSelectedSpot={props.setSelectedSpot}
            selectedSpot={props.selectedSpot}
            geolocation={geolocation}
            setCenter={setCenter}
          />
        );
      })}
    </div >
  );
};

export default List;
