import { useState } from 'react';
import FormFields from './FormFields';

const validateField = (field, value) => {
  if (field.required && !value) return false;
  if (field.regex && !value.replace(/\n/g, ' ').match(field.regex)) return false;
  return true;
};

const Form = ({ children, formGroup, onChange }) => {
  const defaultFormData = formGroup.reduce((acc, field) => {
    return {
      ...acc,
      [field.name]: field.defaultValue || ''
    };
  }, {});
  const defaultErrors = formGroup.reduce((acc, field) => {
    return {
      ...acc,
      [field.name]: false
    };
  }, {});
  const [errors, setErrors] = useState(defaultErrors);
  const [formData, setFormData] = useState(defaultFormData);

  const handleChange = fieldName => e => {
    const nextErrors = errors[fieldName]
      ? {
        ...errors,
        [fieldName]: undefined
      }
      : errors;
    const nextFormData = {
      ...formData,
      [fieldName]: e.target.value
    };
    setErrors(nextErrors);
    setFormData(nextFormData);

    if (onChange) onChange(nextFormData, nextErrors);
  };

  const fields = <FormFields
    formData={formData}
    formGroup={formGroup}
    handleChange={handleChange}
    onChange={onChange}
    errors={errors}
    setErrors={setErrors}
  />;

  const validate = formData => {
    const errors = Object
      .entries(formData)
      .filter(([key, value]) => {
        const field = formGroup.find(f => f.name === key);
        return !validateField(field, value);
      })
      .reduce((acc, [key]) => {
        const field = formGroup.find(f => f.name === key);
        return {
          ...acc,
          [key]: field.helperText
        };
      }, {});

    if (Object.keys(errors).length === 0) return null;
    setErrors(errors);
    return errors;
  };

  const render = children({
    fields,
    getFormData: () => ({ ...formData }),
    validate
  });

  return <>{render}</>;
};

export default Form;
