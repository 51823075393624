import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import setCurrentModal from '../actions/setCurrentModal';
import setSearchQuery from '../actions/setSearchQuery';
import setSelectedSpot from '../actions/setSelectedSpot';
import setSpots from '../actions/setSpots';
import config from '../config';

const actions = {
  setCurrentModal,
  setSearchQuery,
  setSelectedSpot,
  setSpots
};

const useStore = create(
  devtools(
    set => ({
      actions: Object.entries(actions).reduce((acc, [key, func]) => {
        return {
          ...acc,
          [key]: func(set)
        };
      }, {}),
      ...config.defaultState
    }),
    { serialize: true }
  )
);

export default useStore;
