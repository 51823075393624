const Facebook = props => (
  <g>
    <g>
      <path style={props.fillBlack} d="M304.3,604.3L304.3,604.3c-165,0-300-135-300-300v0c0-165,135-300,300-300h0c165,0,300,135,300,300v0C604.3,469.3,469.3,604.3,304.3,604.3z" />
      <g>
        <path style={props.fillWhite} d="M251.7,516.1h76.5V339.6h56.5l11.3-71.3h-67.8v-53.9c0-20,19.1-31.3,37.4-31.3h33V124l-59.1-2.6c-56.5-3.5-87.8,40.9-87.8,93.9v53h-64.3v71.3h64.3V516.1z" />
      </g>
    </g>
  </g>
);

export default Facebook;
