import { Search as SearchIcon } from '@mui/icons-material';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import config from '../../config';
import useStore from '../../hooks/useStore';
import { normalizePlaceholder } from '../../utils';

const Search = props => {
  const { disabled, handleSearchChange, isLargeUp, map, navbarHeight, type } = props;
  const searchQuery = useStore(state => state.searchQuery);
  const setSearchQuery = useStore(state => state.actions.setSearchQuery);
  const query = searchQuery[type];

  const handleChange = handleSearchChange(type);
  const handleSubmit = async e => {
    if (type !== 'place') return;
    const normalizedPlaceholder = normalizePlaceholder(searchQuery.placeholder, query);
    const handleChange = await handleSearchChange(type);

    if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
      e.target.blur();
      const usedQuery = normalizedPlaceholder || query;
      setSearchQuery({ [type]: usedQuery });
      const promise = handleChange({
        target: {
          value: usedQuery
        }
      });

      if (!promise) return new Promise(resolve => resolve());

      return promise.then(results => {
        if (type === 'place') {
          if (!results) return;

          setSearchQuery({
            place: usedQuery
          });
        }
      });
    }
  };

  const normalizedPlaceholder = normalizePlaceholder(searchQuery.placeholder, query);

  if (!map || disabled) return null;

  return (
    <>
      <Input
        startAdornment={
          <InputAdornment position="start"
            sx={{
              marginRight: 0,
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem'
            }}>
            <SearchIcon />
          </InputAdornment>
        }
        sx={{
          backgroundColor: config.colors.secondary,
          color: '#ffffff',
          fontSize: 24,
          height: config.defaultNavbarHeight,
          input: {
            textTransform: 'capitalize'
          },
          padding: 0,
          width: '100%'
        }}
        onKeyDown={handleSubmit}
        value={query}
        onChange={handleChange}
        placeholder={type === 'place' ? (!normalizedPlaceholder ? 'Ort' : '') : 'Namn'}
      />
      {type === 'place' && <Input
        disabled
        startAdornment={
          <InputAdornment position="start"
            sx={{
              color: '#ffffff',
              marginRight: 0,
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem'
            }}>
            <SearchIcon />
          </InputAdornment>
        }
        sx={{
          color: '#ffffff',
          fontSize: 24,
          height: config.defaultNavbarHeight,
          input: {
            'text-fill-color': 'white!important'
          },
          left: 0,
          padding: 0,
          pointerEvents: 'none',
          position: 'absolute',
          top: isLargeUp ? 0 : (navbarHeight || 50),
          width: '100%'
        }}
        placeholder={normalizedPlaceholder}
      />}
    </>
  );
};

export default Search;
