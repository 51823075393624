import config from '../config';

export const paramsToObject = params => {
  const object = Object.fromEntries(params);

  return Object
    .entries(object)
    .reduce((acc, [key]) => {
      return {
        ...acc,
        [key]: params.getAll(key)
      };
    }, {});
};

export const isDefaultLocation = location => {
  return Boolean(
    location.lat === config.googleMapsDefaultCenter.lat &&
    location.lng === config.googleMapsDefaultCenter.lng
  );
};

export const getInitialCenter = filters => {
  return (filters.lat && filters.lng)
    ? {
      lat: Number(filters.lat[0]),
      lng: Number(filters.lng[0]),
      ...(filters.q && {
        q: filters.q[0]
      }),
      ...(filters.name && {
        name: filters.name[0]
      })
    }
    : config.googleMapsDefaultCenter;
};

export const getSpotsWithDistance = (spots, geolocation) => {
  return spots
    .map(spot => {
      if (geolocation.longitude && geolocation.latitude) {
        const metersDistance = window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(geolocation.latitude, geolocation.longitude),
          new window.google.maps.LatLng(spot.location.coordinates[1], spot.location.coordinates[0])
        );

        const kilometersDistance = parseFloat((metersDistance / 1000).toFixed(1));

        return {
          ...spot,
          distance: kilometersDistance
        };
      }

      return spot;
    })
    .filter(Boolean);
};

export const orderSpotsByDistance = spotsWithDistance => spotsWithDistance.sort((a, b) => {
  return a.distance - b.distance;
});

export const isGoldTier = spot => Boolean(spot?.badge?.name?.toUpperCase() === 'GULD');

export const isSilverTier = spot => Boolean(spot?.badge?.name?.toUpperCase() === 'SILVER');

export const orderSpotsByTierDistance = spotsWithDistance => {
  const badgedSpots = spotsWithDistance
    .filter(spot => spot.badge && spot.badge.name.toUpperCase() !== 'BRONS')
    .sort((a, b) => {
      if (isGoldTier(a)) {
        if (isGoldTier(b)) {
          return a.distance - b.distance;
        }
        return -1;
      }
      if (isSilverTier(a)) {
        if (isSilverTier(b)) {
          return a.distance - b.distance;
        }
        return 1;
      }
      return a.distance - b.distance;
    });

  const unbadgedSpots = spotsWithDistance
    .filter(spot => !spot.badge)
    .sort((a, b) => a.distance - b.distance);

  return [
    ...badgedSpots,
    ...unbadgedSpots
  ];
};

export const orderSpotsByName = spotsWithDistance => spotsWithDistance.sort((a, b) => {
  const nameA = a?.user?.name;
  const nameB = b?.user?.name;

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
});

export const normalizePlaceholder = (placeholder, query) => {
  return placeholder.startsWith((query[0] || '').toUpperCase() + query.slice(1)) ? placeholder : '';
};

export const includedInTier = (field, spot) => {
  if (!config.tierDependentSpotData) return true;

  if (isSilverTier(spot)) {
    if (field === 'description') return true;
    if (field === 'address') return true;
    if (field === 'website') return true;
    if (field === 'avatar') return false;
    if (field === 'phone_number') return true;
    if (field === 'email') return true;
    if (field === 'trusted') return true;
  }

  if (isGoldTier(spot)) {
    if (field === 'description') return true;
    if (field === 'address') return true;
    if (field === 'website') return true;
    if (field === 'avatar') return true;
    if (field === 'phone_number') return true;
    if (field === 'email') return true;
    if (field === 'facebook') return true;
    if (field === 'instagram') return true;
    if (field === 'tiktok') return true;
    if (field === 'trusted') return true;
  }

  if (field === 'description') return false;
  if (field === 'address') return true;
  if (field === 'website') return true;
  if (field === 'avatar') return false;

  return false;
};

export const trimDescription = (description = '', spot) => {
  const tier = spot?.badge?.name;

  if (!tier) return '';

  if (tier.toLowerCase() === 'silver') {
    return description.slice(0, 100);
  }

  return description;
};

export const getStyleHack = () => <style>
  {`
.MuiAutocomplete-popperDisablePortal {
  position: relative !important;
  transform: unset !important;
  z-index: unset !important;
}

.MuiAutocomplete-popperDisablePortal .MuiAutocomplete-listbox {
  max-height: unset !important;
}

/* Google Maps overrides */
.gm-style-iw.gm-style-iw-c {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.marker-label {
  background-color: white;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiAutocomplete-listbox {
  margin: 0px!important;
}

input.MuiInputBase-input {
  box-shadow: none!important;
}

button.MuiButtonBase-root:hover,button.MuiButtonBase-root:active, button.MuiButtonBase-root:visited {
  border-color: rgba(0, 0, 0, 0.12)!important;
}
`}
</style>;
