import { Chip, Grid, Typography } from '@mui/material';

const SpotHeader = props => {
  const { spot } = props;
  const [shortDescription] = (spot.address_line_1 ? spot.address_line_1 : '').split('\n');

  return (
    <Grid container sx={{ textAlign: 'left' }}>
      <Grid item xs={spot.distance ? 9 : 12}>
        <Typography variant="h6">{spot?.user?.name || spot.name || shortDescription}</Typography>
      </Grid>
      {spot.distance && <Grid item xs={3} sx={{ textAlign: 'right' }}>
        <Typography>{spot.distance} km</Typography>
        <Typography fontSize={10} >Från din plats</Typography>
      </Grid>}
      {spot.badge && (
        <Chip label={spot.badge.name} sx={{
          backgroundColor: spot.badge.color,
          marginRight: '0.5rem'
        }} />
      )}
    </Grid>
  );
};

export default SpotHeader;
