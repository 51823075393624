import { Box, ToggleButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import MobileNavTab from './MobileNavTab';
import config from '../../config';

const MobileNav = props => {
  const { disabled } = props;
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    if (disabled) return;
    const func = e => {
      if (!props.navbarRef.current) return;
      const overhead = props.navbarRef.current.parentNode.getBoundingClientRect().y;
      const fixed = overhead <= 0;
      setIsFixed(fixed);
    };
    window.addEventListener('scroll', func);
    return () => {
      window.removeEventListener('scroll', func);
    };
  }, [props.navbarRef]);

  if (disabled) return null;

  return (
    <>
      {isFixed && <Box spacing={0} sx={{ height: props.navbarHeight }}></Box>}
      <Box ref={props.navbarRef} spacing={0} sx={{
        background: config.colors.secondary,
        width: '100%',
        zIndex: 100,
        ...(isFixed && {
          left: 0,
          position: 'fixed',
          top: 0
        })
      }}>
        <ToggleButtonGroup
          color="dark"
          exclusive
          variant="outlined"
          fullWidth={true}
          value={props.view}
          sx={{
            '&.MuiToggleButtonGroup-root': {
              borderRadius: 0,
              height: props.navbarHeight
            }
          }}
        >
          <MobileNavTab name="list" view={props.view} setView={props.setView}>
            Lista
          </MobileNavTab>
          <MobileNavTab name="map" view={props.view} setView={props.setView}>
            Karta
          </MobileNavTab>
          <MobileNavTab name="filters" view={props.view} setView={props.setView}>
            Filter
          </MobileNavTab>
        </ToggleButtonGroup>
      </Box>
    </>
  );
};

export default MobileNav;
