import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import ContactText from './ContactText';
import api from '../../../api';
import config from '../../../config';
import Button from '../../Button/Button';
import Form from '../../Form/Form';

const ContactModal = ({ fullScreen, open, setCurrentModal, spot }) => {
  const [submitted, setSubmitted] = useState(false);
  const [status, setStatus] = useState(null);

  const handleSubmit = (getFormData, validate) => () => {
    const formData = getFormData();
    const errors = validate(formData);
    if (!errors) {
      api.spots.contact(spot.id, formData)
        .then(response => {
          if (response.status === 200) {
            setSubmitted(true);
            setStatus('sent');
          } else {
            setStatus('failed');
          }
        });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => (submitted ? status : true) && setCurrentModal(null)}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        Kontakta {spot.name}
      </DialogTitle>
      <Form formGroup={config.formGroups.contactForm}>
        {({ fields, getFormData, validate }) => <>
          <DialogContent>
            <ContactText
              spot={spot}
              status={status}
              submitted={submitted}
              fullScreen={fullScreen}
            />
            {!submitted && fields}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setCurrentModal(null)}
              disabled={submitted && !status}
            >
              {submitted ? 'Stäng' : 'Avbryt'}
            </Button>
            {!submitted && <Button onClick={handleSubmit(getFormData, validate)}>Skicka</Button>}
          </DialogActions>
        </>}
      </Form>
    </Dialog>
  );
};

export default ContactModal;
