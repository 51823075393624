
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox, Chip, capitalize } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import config from '../../config';
import Autocomplete from '../Autocomplete/Autocomplete';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const replaceName = name => {
  if (name === 'city') return 'Stad';
  return name;
};

const Filter = props => {
  const { filters, isLargeUp, map, name, options, setFilters } = props;
  const [open, setOpen] = useState(false);

  const mobileProps = !isLargeUp
    ? {
      disablePortal: true,
      onClose: e => e.type !== 'blur' && setOpen(false),
      onOpen: () => setOpen(true),
      open
    }
    : {};

  const handleChange = (e, value) => {
    setFilters({
      [name]: value
    });

    const nextFilters = {
      ...filters,
      [name]: value
    };

    if (config.zoomOutForInriktning) {
      const nonCityFilterActive = Object.entries(nextFilters)
        .some(([key, value]) => key !== 'city' && key !== 'lng' && key !== 'lat' && key !== 'q' && value && value.length > 0);

      const cityFilterActive = nextFilters?.city?.length > 0;

      if (nonCityFilterActive && !cityFilterActive) {
        map.setZoom(4);
        map.setCenter({
          lat: 60.4087952,
          lng: 15.3836769
        });
      }
    }
  };

  return (
    <Autocomplete
      {...mobileProps}
      isLargeUp={isLargeUp}
      size='small'
      multiple
      options={options}
      noOptionsText={'Inga filtervärden matchar sökningen'}
      value={replaceName(filters[name]) || []}
      renderTags={tags => tags.map(tag => {
        return (
          <Chip key={tag} label={tag} color="primary" sx={{
            color: '#ffffff',
            fontSize: isLargeUp ? '10px' : 16,
            marginBottom: '0.3rem',
            marginRight: '0.3rem'
          }} />
        );
      })}
      onChange={handleChange}
      disableCloseOnSelect={true}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          size='small'
          {...params}
          placeholder={capitalize(replaceName(name))}
          sx={{
            ...(isLargeUp && {
              input: {
                color: '#ffffff',
                minWidth: '170px!important'
              },
              width: 'unset'
            })
          }}
        />
      )}
      sx={{
        '& fieldset': {
          display: 'none'
        },
        ...(isLargeUp && {
          minWidth: 170
        })
      }}
    />
  );
};

export default Filter;
