import { Box } from '@mui/material';
import React from 'react';
import config from '../../config';
import Link from '../Link/Link';

const interpolateText = appFooter => appFooter.text
  .split(/\s/)
  .map(word => {
    if (word.startsWith('{') && word.endsWith('}')) {
      const [propName, index] = word.slice(1, -1).toLowerCase().split('_');
      const props = appFooter[propName + 's'];
      const prop = props[index];

      if (propName === 'url') {
        return (
          <Link key={prop.url + prop.text} direct href={prop.url}>{prop.text} </Link>
        );
      }
      return null;
    }

    return <React.Fragment key={word}>{word} </React.Fragment >;
  })
  .filter(Boolean);

const AppFooter = () => {
  if (config.appFooter) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          fontWeight: 700,
          height: '45px',
          justifyContent: 'center'
        }}
      >
        <div>
          {interpolateText(config.appFooter)}
        </div>
      </Box>
    );
  }
  return null;
};

export default AppFooter;
