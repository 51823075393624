import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';

import { useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';
// import api from './api';
import App from './components/App/App';
// import NotFound from './components/NotFound';
// import Profile from './components/Profile/Profile';
// import ProfileMultiSpot from './components/Profile/ProfileMultiSpot';
import config from './config';
import { getStyleHack } from './utils';
import pkg from '../package.json';

window.appVersion = pkg.version;

const router = createBrowserRouter([
  // {
  //   element: <App />,
  //   path: '/hitta'
  // },
  // {
  //   element: config.multispotProfilePage ? <ProfileMultiSpot /> : <Profile />,
  //   errorElement: <NotFound />,
  //   loader: async ({ params, request }) => {
  //     if (config.multispotProfilePage) {
  //       const user = await api.users.getById(params.id);
  //       return user;
  //     }

  //     const spot = await api.spots.getById(params.id);
  //     return spot;
  //   },
  //   path: '/mottagning/:id'
  // },
  // {
  //   element: <App />,
  //   path: '/'
  // },
  {
    element: <App/>,
    path: '*'
  }
]);

const root = ReactDOM.createRoot(document.getElementById('vn-vinyls-map'));

const theme = createTheme({
  breakpoints: {
    values: {
      lg: config.viewportLarge,
      md: 900,
      sm: 600,
      xl: 1920,
      xs: 0
    }
  },
  palette: {
    dark: {
      contrastText: '#ffffff',
      darker: '#000000',
      main: '#000000'
    },
    light: {
      contrastText: '#000000',
      darker: '#ffffff',
      main: '#ffffff'
    },
    primary: {
      main: config.colors.primary
    }
  }
});

const Root = () => {
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <ThemeProvider theme={theme}>
      <div id='react-container' style={{
        maxWidth: config.viewportLarge,
        ...(isLargeUp && {
          overflow: 'hidden'
        })
      }}>
        {config.useStyleHack && getStyleHack()}
        <RouterProvider router={router} />
      </div>
    </ThemeProvider>
  );
};

root.render(
  config.debug
    ? (
      <React.StrictMode>
        <Root />
      </React.StrictMode>
    )
    : <Root />
);
