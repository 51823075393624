const Tiktok = props => (
  <g>
    <g>
      <path style={props.fillBlack} d="M304.3,604.3L304.3,604.3c-165,0-300-135-300-300v0c0-165,135-300,300-300h0c165,0,300,135,300,300v0C604.3,469.3,469.3,604.3,304.3,604.3z" />
      <path style={props.fillWhite} d="M441.1,206c-17.2-2.4-32.1-11.8-44.7-25.9c-14.9-16.5-23.5-38.4-22.7-59.6h-17.2h-44.7v145.7v101.1c0,29-23.5,52.5-53.3,52.5c-17.2,0-32.9-8.6-43.1-21.9c-6.3-8.6-9.4-19.6-9.4-30.6c0-29,23.5-52.5,52.5-52.5c5.5,0,11,0.8,15.7,2.4v-62.7c-4.7-0.8-10.2-1.6-15.7-1.6c-0.8,0-1.6,0-2.4,0c-62.7,1.6-112,52.5-112,114.4c0,29.8,11.8,57.2,30.6,77.6c21.2,21.9,50.9,36,83.8,36c63.5,0,115.2-50.9,115.2-113.6V238.9c23.5,21.2,54.1,31.3,85.4,29V206C452.8,207.5,446.6,207.5,441.1,206z" />
    </g>
  </g>
);

export default Tiktok;
