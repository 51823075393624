import axios from 'axios';
import config from '../config';

const request = axios.create({
  baseURL: config.apiBaseUrl
});

const api = {
  categories: {
    getAll: async () => {
      try {
        return request
          .get('/categories')
          .then(response => {
            if (response?.data?.success) {
              return response?.data?.data;
            }
            throw new Error('Request failed');
          });
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  },
  spots: {
    contact: async (id, data) => {
      try {
        return request
          .post(`/receptions/${id}/contact`, data)
          .then(response => {
            if (response?.data?.success) {
              return response?.data?.record;
            }
            throw new Error('Request failed');
          });
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    getById: async id => {
      try {
        return request
          .get(`/receptions/${id}`)
          .then(response => {
            if (response?.data?.success) {
              return response?.data?.record;
            }
            throw new Error('Request failed');
          });
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    search: async (params = {}) => {
      try {
        return request
          .get('/receptions', {
            params: {
              ...Object
                .entries(params)
                .filter(([key]) => !(['lng', 'long', 'lat', 'q', 'name', 'hideMap'].includes(key)))
                .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
              lat: Array.isArray(params.lat) ? params.lat[0] : params.lat,
              long: Array.isArray(params.lng) ? params.lng[0] : params.lng,
              name: (Array.isArray(params.name) ? params.name[0] : params.name) || undefined
            }
          })
          .then(response => {
            if (response?.data?.success) {
              return response?.data?.results;
            }
            throw new Error('Request failed');
          });
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  },
  users: {
    getById: async id => {
      try {
        return request
          .get(`/therapists/${id}`)
          .then(response => {
            if (response?.data?.success) {
              return response?.data?.record;
            }
            throw new Error('Request failed');
          });
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }
};

export default api;
