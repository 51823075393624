import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={null}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-content ': {
    alignItems: 'center'
  }
}));

export default AccordionSummary;
