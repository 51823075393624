import {
  unstable_composeClasses as composeClasses,
  createFilterOptions,
  useAutocomplete
} from '@mui/base';
import { ArrowDropDown as ArrowDropDownIcon, Close as ClearIcon } from '@mui/icons-material';
import { Chip, IconButton, ListSubheader, Paper, Popper, autocompleteClasses, capitalize, filledInputClasses, getAutocompleteUtilityClass, inputBaseClasses, inputClasses, outlinedInputClasses, styled, useThemeProps } from '@mui/material';
import { alpha } from '@mui/system';
import { chainPropTypes, integerPropType } from '@mui/utils';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';
import config from '../../config';

const useUtilityClasses = (ownerState) => {
  const {
    classes,
    disablePortal,
    expanded,
    focused,
    fullWidth,
    hasClearIcon,
    hasPopupIcon,
    inputFocused,
    popupOpen,
    size
  } = ownerState;

  const slots = {
    clearIndicator: ['clearIndicator'],
    endAdornment: ['endAdornment'],
    groupLabel: ['groupLabel'],
    groupUl: ['groupUl'],
    input: ['input', inputFocused && 'inputFocused'],
    inputRoot: ['inputRoot'],
    listbox: ['listbox'],
    loading: ['loading'],
    noOptions: ['noOptions'],
    option: ['option'],
    paper: ['paper'],
    popper: ['popper', disablePortal && 'popperDisablePortal'],
    popupIndicator: ['popupIndicator', popupOpen && 'popupIndicatorOpen'],
    root: [
      'root',
      expanded && 'expanded',
      focused && 'focused',
      fullWidth && 'fullWidth',
      hasClearIcon && 'hasClearIcon',
      hasPopupIcon && 'hasPopupIcon'
    ],
    tag: ['tag', `tagSize${capitalize(size)}`]
  };

  return composeClasses(slots, getAutocompleteUtilityClass, classes);
};

const AutocompleteRoot = styled('div', {
  name: 'MuiAutocomplete',
  overridesResolver: (props, styles) => {
    const { ownerState } = props;
    const { fullWidth, hasClearIcon, hasPopupIcon, inputFocused, size } = ownerState;

    return [
      { [`& .${autocompleteClasses.tag}`]: styles.tag },
      { [`& .${autocompleteClasses.tag}`]: styles[`tagSize${capitalize(size)}`] },
      { [`& .${autocompleteClasses.inputRoot}`]: styles.inputRoot },
      { [`& .${autocompleteClasses.input}`]: styles.input },
      { [`& .${autocompleteClasses.input}`]: inputFocused && styles.inputFocused },
      styles.root,
      fullWidth && styles.fullWidth,
      hasPopupIcon && styles.hasPopupIcon,
      hasClearIcon && styles.hasClearIcon
    ];
  },
  slot: 'Root'
})(({ ownerState }) => ({
  [`&.${autocompleteClasses.focused} .${autocompleteClasses.clearIndicator}`]: {
    visibility: 'visible'
  },
  /* Avoid double tap issue on iOS */
  '@media (pointer: fine)': {
    [`&:hover .${autocompleteClasses.clearIndicator}`]: {
      visibility: 'visible'
    }
  },
  ...(ownerState.fullWidth && {
    width: '100%'
  }),
  [`& .${autocompleteClasses.tag}`]: {
    margin: 3,
    maxWidth: 'calc(100% - 6px)',
    ...(ownerState.size === 'small' && {
      margin: 2,
      maxWidth: 'calc(100% - 4px)'
    })
  },
  [`& .${autocompleteClasses.inputRoot}`]: {
    flexWrap: 'wrap',
    [`.${autocompleteClasses.hasPopupIcon}&, .${autocompleteClasses.hasClearIcon}&`]: {
      paddingRight: 26 + 4
    },
    [`.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon}&`]: {
      paddingRight: 52 + 4
    },
    [`& .${autocompleteClasses.input}`]: {
      minWidth: 30,
      width: 0
    }
  },
  [`& .${inputClasses.root}`]: {
    '& .MuiInput-input': {
      padding: '4px 4px 4px 0px'
    },
    paddingBottom: 1
  },
  [`& .${inputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
    [`& .${inputClasses.input}`]: {
      padding: '2px 4px 3px 0'
    }
  },
  [`& .${outlinedInputClasses.root}`]: {
    padding: 9,
    [`.${autocompleteClasses.hasPopupIcon}&, .${autocompleteClasses.hasClearIcon}&`]: {
      paddingRight: 26 + 4 + 9
    },
    [`.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon}&`]: {
      paddingRight: 52 + 4 + 9
    },
    [`& .${autocompleteClasses.input}`]: {
      padding: '7.5px 4px 7.5px 5px'
    },
    [`& .${autocompleteClasses.endAdornment}`]: {
      right: 9
    }
  },
  [`& .${outlinedInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {

    paddingBottom: 6,

    paddingLeft: 6,
    // Don't specify paddingRight, as it overrides the default value set when there is only
    // one of the popup or clear icon as the specificity is equal so the latter one wins
    paddingTop: 6,
    [`& .${autocompleteClasses.input}`]: {
      padding: '2.5px 4px 2.5px 8px'
    }
  },
  [`& .${filledInputClasses.root}`]: {
    paddingLeft: 8,
    paddingTop: 19,
    [`.${autocompleteClasses.hasPopupIcon}&, .${autocompleteClasses.hasClearIcon}&`]: {
      paddingRight: 26 + 4 + 9
    },
    [`.${autocompleteClasses.hasPopupIcon}.${autocompleteClasses.hasClearIcon}&`]: {
      paddingRight: 52 + 4 + 9
    },
    [`& .${filledInputClasses.input}`]: {
      padding: '7px 4px'
    },
    [`& .${autocompleteClasses.endAdornment}`]: {
      right: 9
    }
  },
  [`& .${filledInputClasses.root}.${inputBaseClasses.sizeSmall}`]: {
    paddingBottom: 1,
    [`& .${filledInputClasses.input}`]: {
      padding: '2.5px 4px'
    }
  },
  [`& .${inputBaseClasses.hiddenLabel}`]: {
    paddingTop: 8
  },
  [`& .${filledInputClasses.root}.${inputBaseClasses.hiddenLabel}`]: {
    paddingBottom: 0,
    paddingTop: 0,
    [`& .${autocompleteClasses.input}`]: {
      paddingBottom: 17,
      paddingTop: 16
    }
  },
  [`& .${filledInputClasses.root}.${inputBaseClasses.hiddenLabel}.${inputBaseClasses.sizeSmall}`]: {
    [`& .${autocompleteClasses.input}`]: {
      paddingBottom: 9,
      paddingTop: 8
    }
  },
  [`& .${autocompleteClasses.input}`]: {
    flexGrow: 1,
    opacity: 0,
    textOverflow: 'ellipsis',
    ...(ownerState.inputFocused && {
      opacity: 1
    })
  }
}));

const AutocompleteEndAdornment = styled('div', {
  name: 'MuiAutocomplete',
  overridesResolver: (props, styles) => styles.endAdornment,
  slot: 'EndAdornment'
})({
  // We use a position absolute to support wrapping tags.
  position: 'absolute',
  right: 0,
  top: 'calc(50% - 14px)' // Center vertically
});

const AutocompleteClearIndicator = styled(IconButton, {
  name: 'MuiAutocomplete',
  overridesResolver: (props, styles) => styles.clearIndicator,
  slot: 'ClearIndicator'
})({
  marginRight: -2,
  padding: 4,
  visibility: 'hidden'
});

const AutocompletePopupIndicator = styled(IconButton, {
  name: 'MuiAutocomplete',
  overridesResolver: ({ ownerState }, styles) => ({
    ...styles.popupIndicator,
    ...(ownerState.popupOpen && styles.popupIndicatorOpen)
  }),
  slot: 'PopupIndicator'
})(({ ownerState }) => ({
  marginRight: -2,
  padding: 2,
  ...(ownerState.popupOpen && {
    transform: 'rotate(180deg)'
  })
}));

const AutocompletePopper = styled(Popper, {
  name: 'MuiAutocomplete',
  overridesResolver: (props, styles) => {
    const { ownerState } = props;

    return [
      { [`& .${autocompleteClasses.option}`]: styles.option },
      styles.popper,
      ownerState.disablePortal && styles.popperDisablePortal
    ];
  },
  slot: 'Popper'
})(({ ownerState, theme }) => ({
  zIndex: (theme.vars || theme).zIndex.modal,
  ...(ownerState.disablePortal && {
    position: 'absolute'
  })
}));

const AutocompletePaper = styled(Paper, {
  name: 'MuiAutocomplete',
  overridesResolver: (props, styles) => styles.paper,
  slot: 'Paper'
})(({ theme }) => ({
  ...theme.typography.body1,
  overflow: 'auto'
}));

const AutocompleteLoading = styled('div', {
  name: 'MuiAutocomplete',
  overridesResolver: (props, styles) => styles.loading,
  slot: 'Loading'
})(({ theme }) => ({
  color: (theme.vars || theme).palette.text.secondary,
  padding: '14px 16px'
}));

const AutocompleteNoOptions = styled('div', {
  name: 'MuiAutocomplete',
  overridesResolver: (props, styles) => styles.noOptions,
  slot: 'NoOptions'
})(({ theme }) => ({
  color: (theme.vars || theme).palette.text.secondary,
  padding: '14px 16px'
}));

const AutocompleteListbox = styled('div', {
  name: 'MuiAutocomplete',
  overridesResolver: (props, styles) => styles.listbox,
  slot: 'Listbox'
})(({ theme }) => ({
  listStyle: 'none',
  margin: 0,
  maxHeight: '40vh',
  overflow: 'auto',
  padding: '8px 0',
  position: 'relative',
  [`& .${autocompleteClasses.option}`]: {
    '&[aria-disabled="true"]': {
      opacity: (theme.vars || theme).palette.action.disabledOpacity,
      pointerEvents: 'none'
    },
    '&[aria-selected="true"]': {
      backgroundColor: theme.vars
        ? `rgba(${theme.vars.palette.primary.mainChannel} / ${theme.vars.palette.action.selectedOpacity})`
        : alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      [`&.${autocompleteClasses.focused}`]: {
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: (theme.vars || theme).palette.action.selected
        },

        backgroundColor: theme.vars
          ? `rgba(${theme.vars.palette.primary.mainChannel} / calc(${theme.vars.palette.action.selectedOpacity} + ${theme.vars.palette.action.hoverOpacity}))`
          : alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
          )
      },
      [`&.${autocompleteClasses.focusVisible}`]: {
        backgroundColor: theme.vars
          ? `rgba(${theme.vars.palette.primary.mainChannel} / calc(${theme.vars.palette.action.selectedOpacity} + ${theme.vars.palette.action.focusOpacity}))`
          : alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity
          )
      }
    },
    WebkitTapHighlightColor: 'transparent',
    alignItems: 'center',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    minHeight: 48,
    outline: '0',
    overflow: 'hidden',
    paddingBottom: 6,
    paddingLeft: 16,
    [theme.breakpoints.up('sm')]: {
      minHeight: 'auto'
    },
    [`&.${autocompleteClasses.focused}`]: {
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      },

      backgroundColor: (theme.vars || theme).palette.action.hover
    },
    paddingRight: 16,
    [`&.${autocompleteClasses.focusVisible}`]: {
      backgroundColor: (theme.vars || theme).palette.action.focus
    },
    paddingTop: 6
  }
}));

const AutocompleteGroupLabel = styled(ListSubheader, {
  name: 'MuiAutocomplete',
  overridesResolver: (props, styles) => styles.groupLabel,
  slot: 'GroupLabel'
})(({ theme }) => ({
  backgroundColor: (theme.vars || theme).palette.background.paper,
  top: -8
}));

const AutocompleteGroupUl = styled('ul', {
  name: 'MuiAutocomplete',
  overridesResolver: (props, styles) => styles.groupUl,
  slot: 'GroupUl'
})({
  padding: 0,
  [`& .${autocompleteClasses.option}`]: {
    paddingLeft: 24
  }
});

export { createFilterOptions };

const Autocomplete = React.forwardRef(function Autocomplete(inProps, ref) {
  const props = useThemeProps({ name: 'MuiAutocomplete', props: inProps });
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    ChipProps,
    ListboxComponent = 'ul',
    ListboxProps,
    PaperComponent = Paper,
    PopperComponent = Popper,
    autoComplete = false,
    autoHighlight = false,
    autoSelect = false,
    blurOnSelect = false,
    className,
    clearIcon = <ClearIcon fontSize="small" />,
    clearOnBlur = !props.freeSolo,
    clearOnEscape = false,
    clearText = 'Clear',
    closeText = 'Close',
    componentsProps = {},
    defaultValue = props.multiple ? [] : null,
    disableClearable = false,
    disableCloseOnSelect = false,
    disableListWrap = false,
    disablePortal = false,
    disabled = false,
    disabledItemsFocusable = false,
    filterOptions,
    filterSelectedOptions = false,
    forcePopupIcon = 'auto',
    freeSolo = false,
    fullWidth = false,
    getLimitTagsText = (more) => `+${more}`,
    getOptionDisabled,
    getOptionLabel = (option) => option.label ?? option,
    groupBy,
    handleHomeEndKeys = !props.freeSolo,
    id: idProp,
    includeInputInList = false,
    inputValue: inputValueProp,
    isOptionEqualToValue,
    limitTags = -1,
    loading = false,
    loadingText = 'Loading…',
    multiple = false,
    noOptionsText = 'No options',
    onChange,
    onClose,
    onHighlightChange,
    onInputChange,
    onOpen,
    open,
    openOnFocus = false,
    openText = 'Open',
    options,
    popupIcon = <ArrowDropDownIcon />,
    readOnly = false,
    renderGroup: renderGroupProp,
    renderInput,
    renderOption: renderOptionProp,
    renderTags,
    selectOnFocus = !props.freeSolo,
    size = 'medium',
    slotProps = {},
    value: valueProp,
    ...other
  } = props;
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const {
    anchorEl,
    dirty,
    expanded,
    focused,
    focusedTag,
    getClearProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getPopupIndicatorProps,
    getRootProps,
    getTagProps,
    groupedOptions,
    id,
    inputValue,
    popupOpen,
    setAnchorEl,
    value
  } = useAutocomplete({ ...props, componentName: 'Autocomplete' });

  const hasClearIcon = !disableClearable && !disabled && dirty && !readOnly;
  const hasPopupIcon = (!freeSolo || forcePopupIcon === true) && forcePopupIcon !== false;

  const { onMouseDown: handleInputMouseDown } = getInputProps();

  // If you modify this, make sure to keep the `AutocompleteOwnerState` type in sync.
  const ownerState = {
    ...props,
    disablePortal,
    expanded,
    focused,
    fullWidth,
    hasClearIcon,
    hasPopupIcon,
    inputFocused: focusedTag === -1,
    popupOpen,
    size
  };

  const classes = useUtilityClasses(ownerState);

  let startAdornment;

  if (multiple && value.length > 0) {
    const getCustomizedTagProps = (params) => ({
      className: classes.tag,
      disabled,
      ...getTagProps(params)
    });

    if (renderTags) {
      startAdornment = renderTags(value, getCustomizedTagProps, ownerState);
    } else {
      startAdornment = value.map((option, index) => (
        <Chip
          label={getOptionLabel(option)}
          size={size}
          {...getCustomizedTagProps({ index })}
          {...ChipProps}
        />
      ));
    }
  }

  if (limitTags > -1 && Array.isArray(startAdornment)) {
    const more = startAdornment.length - limitTags;
    if (!focused && more > 0) {
      startAdornment = startAdornment.splice(0, limitTags);
      startAdornment.push(
        <span className={classes.tag} key={startAdornment.length}>
          {getLimitTagsText(more)}
        </span>
      );
    }
  }

  const defaultRenderGroup = (params) => (
    <li key={params.key}>
      <AutocompleteGroupLabel
        className={classes.groupLabel}
        ownerState={ownerState}
        component="div"
      >
        {params.group}
      </AutocompleteGroupLabel>
      <AutocompleteGroupUl className={classes.groupUl} ownerState={ownerState}>
        {params.children}
      </AutocompleteGroupUl>
    </li>
  );

  const renderGroup = renderGroupProp || defaultRenderGroup;
  const defaultRenderOption = (props2, option) => <li {...props2}>{getOptionLabel(option)}</li>;
  const renderOption = renderOptionProp || defaultRenderOption;

  const renderListOption = (option, index) => {
    const optionProps = getOptionProps({ index, option });

    return renderOption({ ...optionProps, className: classes.option }, option, {
      index,
      inputValue,
      selected: optionProps['aria-selected']
    });
  };

  const clearIndicatorSlotProps = slotProps.clearIndicator ?? componentsProps.clearIndicator;
  const paperSlotProps = slotProps.paper ?? componentsProps.paper;
  const popperSlotProps = slotProps.popper ?? componentsProps.popper;
  const popupIndicatorSlotProps = slotProps.popupIndicator ?? componentsProps.popupIndicator;

  return (
    <React.Fragment>
      <AutocompleteRoot
        ref={ref}
        className={clsx(classes.root, className)}
        ownerState={ownerState}
        {...getRootProps(other)}
      >
        {renderInput({
          InputLabelProps: getInputLabelProps(),
          InputProps: {
            className: classes.inputRoot,
            onClick: (event) => {
              if (event.target === event.currentTarget) {
                handleInputMouseDown(event);
              }
            },
            ref: setAnchorEl,
            // startAdornment,
            ...((hasClearIcon || hasPopupIcon) && {
              endAdornment: (
                <>
                  <AutocompleteEndAdornment className={classes.endAdornment} ownerState={ownerState}>
                    {hasClearIcon
                      ? (
                        <AutocompleteClearIndicator
                          {...getClearProps()}
                          aria-label={clearText}
                          title={clearText}
                          ownerState={ownerState}
                          {...clearIndicatorSlotProps}
                          className={clsx(classes.clearIndicator, clearIndicatorSlotProps?.className)}
                          sx={{
                            ...(props.isLargeUp && {
                              '&:hover': {
                                color: '#ffffff',
                                opacity: 0.5
                              },
                              backgroundColor: config.colors.secondary,
                              color: '#ffffff'
                            })
                          }}
                        >
                          {clearIcon}
                        </AutocompleteClearIndicator>
                      )
                      : null}

                    {hasPopupIcon
                      ? (
                        <AutocompletePopupIndicator
                          {...getPopupIndicatorProps()}
                          disabled={disabled}
                          aria-label={popupOpen ? closeText : openText}
                          title={popupOpen ? closeText : openText}
                          ownerState={ownerState}
                          {...popupIndicatorSlotProps}
                          className={clsx(classes.popupIndicator, popupIndicatorSlotProps?.className)}
                          sx={{
                            ...(props.isLargeUp && {
                              '&:hover': {
                                color: '#ffffff',
                                opacity: 0.5
                              },
                              backgroundColor: config.colors.secondary,
                              color: '#ffffff'
                            })
                          }}
                        >
                          {popupIcon}
                        </AutocompletePopupIndicator>
                      )
                      : null}
                  </AutocompleteEndAdornment>
                  {startAdornment}
                </>
              )
            })
          },
          disabled,
          fullWidth: true,
          id,
          inputProps: {
            className: classes.input,
            disabled,
            readOnly,
            ...getInputProps()
          },
          size: size === 'small' ? 'small' : undefined
        })}
      </AutocompleteRoot>
      {anchorEl
        ? (
          <AutocompletePopper
            as={PopperComponent}
            disablePortal={disablePortal}
            style={{
              width: anchorEl ? anchorEl.clientWidth : null
            }}
            ownerState={ownerState}
            role="presentation"
            anchorEl={anchorEl}
            open={popupOpen}
            {...popperSlotProps}
            className={clsx(classes.popper, popperSlotProps?.className)}
          >
            <AutocompletePaper
              ownerState={ownerState}
              as={PaperComponent}
              {...paperSlotProps}
              className={clsx(classes.paper, paperSlotProps?.className)}
            >
              {loading && groupedOptions.length === 0
                ? (
                  <AutocompleteLoading className={classes.loading} ownerState={ownerState}>
                    {loadingText}
                  </AutocompleteLoading>
                )
                : null}
              {groupedOptions.length === 0 && !freeSolo && !loading
                ? (
                  <AutocompleteNoOptions
                    className={classes.noOptions}
                    ownerState={ownerState}
                    role="presentation"
                    onMouseDown={(event) => {
                      // Prevent input blur when interacting with the "no options" content
                      event.preventDefault();
                    }}
                  >
                    {noOptionsText}
                  </AutocompleteNoOptions>
                )
                : null}
              {groupedOptions.length > 0
                ? (
                  <AutocompleteListbox
                    as={ListboxComponent}
                    className={classes.listbox}
                    ownerState={ownerState}
                    {...getListboxProps()}
                    {...ListboxProps}
                  >
                    {groupedOptions.map((option, index) => {
                      if (groupBy) {
                        return renderGroup({
                          children: option.options.map((option2, index2) =>
                            renderListOption(option2, option.index + index2)
                          ),
                          group: option.group,
                          key: option.key
                        });
                      }
                      return renderListOption(option, index);
                    })}
                  </AutocompleteListbox>
                )
                : null}
            </AutocompletePaper>
          </AutocompletePopper>
        )
        : null}
    </React.Fragment>
  );
});

Autocomplete.propTypes /* remove-proptypes */ = {

  /**
   * Props applied to the [`Chip`](/material-ui/api/chip/) element.
   */
  ChipProps: PropTypes.object,

  /**
   * The component used to render the listbox.
   * @default 'ul'
   */
  ListboxComponent: PropTypes.elementType,

  /**
   * Props applied to the Listbox element.
   */
  ListboxProps: PropTypes.object,

  /**
   * The component used to render the body of the popup.
   * @default Paper
   */
  PaperComponent: PropTypes.elementType,

  /**
   * The component used to position the popup.
   * @default Popper
   */
  PopperComponent: PropTypes.elementType,

  // ----------------------------- Warning --------------------------------
  // | These PropTypes are generated from the TypeScript type definitions |
  // |     To update them edit the d.ts file and run "yarn proptypes"     |
  // ----------------------------------------------------------------------
  /**
   * If `true`, the portion of the selected suggestion that has not been typed by the user,
   * known as the completion string, appears inline after the input cursor in the textbox.
   * The inline completion string is visually highlighted and has a selected state.
   * @default false
   */
  autoComplete: PropTypes.bool,

  /**
   * If `true`, the first option is automatically highlighted.
   * @default false
   */
  autoHighlight: PropTypes.bool,

  /**
   * If `true`, the selected option becomes the value of the input
   * when the Autocomplete loses focus unless the user chooses
   * a different option or changes the character string in the input.
   *
   * When using `freeSolo` mode, the typed value will be the input value
   * if the Autocomplete loses focus without highlighting an option.
   * @default false
   */
  autoSelect: PropTypes.bool,

  /**
   * Control if the input should be blurred when an option is selected:
   *
   * - `false` the input is not blurred.
   * - `true` the input is always blurred.
   * - `touch` the input is blurred after a touch event.
   * - `mouse` the input is blurred after a mouse event.
   * @default false
   */
  blurOnSelect: PropTypes.oneOfType([PropTypes.oneOf(['mouse', 'touch']), PropTypes.bool]),

  /**
   * @ignore
   */
  className: PropTypes.string,

  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,

  /**
   * The icon to display in place of the default clear icon.
   * @default <ClearIcon fontSize="small" />
   */
  clearIcon: PropTypes.node,

  /**
   * If `true`, the input's text is cleared on blur if no value is selected.
   *
   * Set to `true` if you want to help the user enter a new value.
   * Set to `false` if you want to help the user resume their search.
   * @default !props.freeSolo
   */
  clearOnBlur: PropTypes.bool,

  /**
   * If `true`, clear all values when the user presses escape and the popup is closed.
   * @default false
   */
  clearOnEscape: PropTypes.bool,

  /**
   * Override the default text for the *clear* icon button.
   *
   * For localization purposes, you can use the provided [translations](/material-ui/guides/localization/).
   * @default 'Clear'
   */
  clearText: PropTypes.string,

  /**
   * Override the default text for the *close popup* icon button.
   *
   * For localization purposes, you can use the provided [translations](/material-ui/guides/localization/).
   * @default 'Close'
   */
  closeText: PropTypes.string,

  /**
   * The props used for each slot inside.
   * @default {}
   */
  componentsProps: PropTypes.shape({
    clearIndicator: PropTypes.object,
    paper: PropTypes.object,
    popper: PropTypes.object,
    popupIndicator: PropTypes.object
  }),

  /**
   * The default value. Use when the component is not controlled.
   * @default props.multiple ? [] : null
   */
  defaultValue: chainPropTypes(PropTypes.any, (props) => {
    if (props.multiple && props.defaultValue !== undefined && !Array.isArray(props.defaultValue)) {
      return new Error(
        [
          'MUI: The Autocomplete expects the `defaultValue` prop to be an array when `multiple={true}` or undefined.',
          `However, ${props.defaultValue} was provided.`
        ].join('\n')
      );
    }
    return null;
  }),

  /**
   * If `true`, the input can't be cleared.
   * @default false
   */
  disableClearable: PropTypes.bool,

  /**
   * If `true`, the popup won't close when a value is selected.
   * @default false
   */
  disableCloseOnSelect: PropTypes.bool,

  /**
   * If `true`, the list box in the popup will not wrap focus.
   * @default false
   */
  disableListWrap: PropTypes.bool,

  /**
   * If `true`, the `Popper` content will be under the DOM hierarchy of the parent component.
   * @default false
   */
  disablePortal: PropTypes.bool,

  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled: PropTypes.bool,

  /**
   * If `true`, will allow focus on disabled items.
   * @default false
   */
  disabledItemsFocusable: PropTypes.bool,

  /**
   * A function that determines the filtered options to be rendered on search.
   *
   * @param {T[]} options The options to render.
   * @param {object} state The state of the component.
   * @returns {T[]}
   */
  filterOptions: PropTypes.func,

  /**
   * If `true`, hide the selected options from the list box.
   * @default false
   */
  filterSelectedOptions: PropTypes.bool,

  /**
   * Force the visibility display of the popup icon.
   * @default 'auto'
   */
  forcePopupIcon: PropTypes.oneOfType([PropTypes.oneOf(['auto']), PropTypes.bool]),

  /**
   * If `true`, the Autocomplete is free solo, meaning that the user input is not bound to provided options.
   * @default false
   */
  freeSolo: PropTypes.bool,

  /**
   * If `true`, the input will take up the full width of its container.
   * @default false
   */
  fullWidth: PropTypes.bool,

  /**
   * The label to display when the tags are truncated (`limitTags`).
   *
   * @param {number} more The number of truncated tags.
   * @returns {ReactNode}
   * @default (more) => `+${more}`
   */
  getLimitTagsText: PropTypes.func,

  /**
   * Used to determine the disabled state for a given option.
   *
   * @param {T} option The option to test.
   * @returns {boolean}
   */
  getOptionDisabled: PropTypes.func,

  /**
   * Used to determine the string value for a given option.
   * It's used to fill the input (and the list box options if `renderOption` is not provided).
   *
   * If used in free solo mode, it must accept both the type of the options and a string.
   *
   * @param {T} option
   * @returns {string}
   * @default (option) => option.label ?? option
   */
  getOptionLabel: PropTypes.func,

  /**
   * If provided, the options will be grouped under the returned string.
   * The groupBy value is also used as the text for group headings when `renderGroup` is not provided.
   *
   * @param {T} options The options to group.
   * @returns {string}
   */
  groupBy: PropTypes.func,

  /**
   * If `true`, the component handles the "Home" and "End" keys when the popup is open.
   * It should move focus to the first option and last option, respectively.
   * @default !props.freeSolo
   */
  handleHomeEndKeys: PropTypes.bool,

  /**
   * This prop is used to help implement the accessibility logic.
   * If you don't provide an id it will fall back to a randomly generated one.
   */
  id: PropTypes.string,

  /**
   * If `true`, the highlight can move to the input.
   * @default false
   */
  includeInputInList: PropTypes.bool,

  /**
   * The input value.
   */
  inputValue: PropTypes.string,

  /**
   * Used to determine if the option represents the given value.
   * Uses strict equality by default.
   * ⚠️ Both arguments need to be handled, an option can only match with one value.
   *
   * @param {T} option The option to test.
   * @param {T} value The value to test against.
   * @returns {boolean}
   */
  isOptionEqualToValue: PropTypes.func,

  /**
   * The maximum number of tags that will be visible when not focused.
   * Set `-1` to disable the limit.
   * @default -1
   */
  limitTags: integerPropType,

  /**
   * If `true`, the component is in a loading state.
   * This shows the `loadingText` in place of suggestions (only if there are no suggestions to show, e.g. `options` are empty).
   * @default false
   */
  loading: PropTypes.bool,

  /**
   * Text to display when in a loading state.
   *
   * For localization purposes, you can use the provided [translations](/material-ui/guides/localization/).
   * @default 'Loading…'
   */
  loadingText: PropTypes.node,

  /**
   * If `true`, `value` must be an array and the menu will support multiple selections.
   * @default false
   */
  multiple: PropTypes.bool,

  /**
   * Text to display when there are no options.
   *
   * For localization purposes, you can use the provided [translations](/material-ui/guides/localization/).
   * @default 'No options'
   */
  noOptionsText: PropTypes.node,

  /**
   * Callback fired when the value changes.
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * @param {T|T[]} value The new value of the component.
   * @param {string} reason One of "createOption", "selectOption", "removeOption", "blur" or "clear".
   * @param {string} [details]
   */
  onChange: PropTypes.func,

  /**
   * Callback fired when the popup requests to be closed.
   * Use in controlled mode (see open).
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * @param {string} reason Can be: `"toggleInput"`, `"escape"`, `"selectOption"`, `"removeOption"`, `"blur"`.
   */
  onClose: PropTypes.func,

  /**
   * Callback fired when the highlight option changes.
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * @param {T} option The highlighted option.
   * @param {string} reason Can be: `"keyboard"`, `"auto"`, `"mouse"`, `"touch"`.
   */
  onHighlightChange: PropTypes.func,

  /**
   * Callback fired when the input value changes.
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   * @param {string} value The new value of the text input.
   * @param {string} reason Can be: `"input"` (user input), `"reset"` (programmatic change), `"clear"`.
   */
  onInputChange: PropTypes.func,

  /**
   * Callback fired when the popup requests to be opened.
   * Use in controlled mode (see open).
   *
   * @param {React.SyntheticEvent} event The event source of the callback.
   */
  onOpen: PropTypes.func,

  /**
   * If `true`, the component is shown.
   */
  open: PropTypes.bool,

  /**
   * If `true`, the popup will open on input focus.
   * @default false
   */
  openOnFocus: PropTypes.bool,

  /**
   * Override the default text for the *open popup* icon button.
   *
   * For localization purposes, you can use the provided [translations](/material-ui/guides/localization/).
   * @default 'Open'
   */
  openText: PropTypes.string,

  /**
   * Array of options.
   */
  options: PropTypes.array.isRequired,
  /**
   * The icon to display in place of the default popup icon.
   * @default <ArrowDropDownIcon />
   */
  popupIcon: PropTypes.node,
  /**
   * If `true`, the component becomes readonly. It is also supported for multiple tags where the tag cannot be deleted.
   * @default false
   */
  readOnly: PropTypes.bool,
  /**
   * Render the group.
   *
   * @param {AutocompleteRenderGroupParams} params The group to render.
   * @returns {ReactNode}
   */
  renderGroup: PropTypes.func,
  /**
   * Render the input.
   *
   * @param {object} params
   * @returns {ReactNode}
   */
  renderInput: PropTypes.func.isRequired,
  /**
   * Render the option, use `getOptionLabel` by default.
   *
   * @param {object} props The props to apply on the li element.
   * @param {T} option The option to render.
   * @param {object} state The state of the component.
   * @returns {ReactNode}
   */
  renderOption: PropTypes.func,
  /**
   * Render the selected value.
   *
   * @param {T[]} value The `value` provided to the component.
   * @param {function} getTagProps A tag props getter.
   * @param {object} ownerState The state of the Autocomplete component.
   * @returns {ReactNode}
   */
  renderTags: PropTypes.func,
  /**
   * If `true`, the input's text is selected on focus.
   * It helps the user clear the selected value.
   * @default !props.freeSolo
   */
  selectOnFocus: PropTypes.bool,
  /**
   * The size of the component.
   * @default 'medium'
   */
  size: PropTypes /* @typescript-to-proptypes-ignore */.oneOfType([
    PropTypes.oneOf(['small', 'medium']),
    PropTypes.string
  ]),
  /**
   * The props used for each slot inside.
   * @default {}
   */
  slotProps: PropTypes.shape({
    clearIndicator: PropTypes.object,
    paper: PropTypes.object,
    popper: PropTypes.object,
    popupIndicator: PropTypes.object
  }),
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
    PropTypes.func,
    PropTypes.object
  ]),
  /**
   * The value of the autocomplete.
   *
   * The value must have reference equality with the option in order to be selected.
   * You can customize the equality behavior with the `isOptionEqualToValue` prop.
   */
  value: chainPropTypes(PropTypes.any, (props) => {
    if (props.multiple && props.value !== undefined && !Array.isArray(props.value)) {
      return new Error(
        [
          'MUI: The Autocomplete expects the `value` prop to be an array when `multiple={true}` or undefined.',
          `However, ${props.value} was provided.`
        ].join('\n')
      );
    }
    return null;
  })
};

export default Autocomplete;
