import { Call as CallIcon, Email as EmailIcon, Facebook as FacebookIcon, Instagram as InstagramIcon, Place as PlaceIcon, Public as PublicIcon } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
// import { useLinkClickHandler } from 'react-router-dom';
import config from '../../config';
import useStore from '../../hooks/useStore';
import { includedInTier, trimDescription } from '../../utils';
import Button from '../Button/Button';
import Link from '../Link/Link';
import SocialIcon from '../SocialIcon/SocialIcon';

const SpotContent = props => {
  const { hideProfileButton, spot } = props;
  // const profileId = config.multispotProfilePage ? (spot?.user_id || spot.id) : spot.id;
  // const handleClick = useLinkClickHandler(`/${profileId}`);
  const setCurrentModal = useStore(state => state.actions.setCurrentModal);

  return (
    <Grid container>
      {includedInTier('trusted', spot) && <Typography variant='subtitle2'>Trusted by VN Vinyls</Typography>}
      {(spot.description || spot.about_me) && includedInTier('description', spot) && <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
        <Typography component={Stack}>
          <ReactMarkdown>{trimDescription(spot.description || spot.about_me, spot)}</ReactMarkdown>
        </Typography>
      </Grid>}
      {spot.address_line_1 && includedInTier('address', spot) && <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
        <Stack spacing={{ xs: 1 }} direction="row" alignItems="center">
          <PlaceIcon />
          <Link href={`/?lng=${spot.location.coordinates[0]}&lat=${spot.location.coordinates[1]}`}>
            {spot.address_line_1
              .split('\n')
              .join(', ')
            }
          </Link>
        </Stack>
      </Grid>}
      {!config.hideSpotEmail && spot.email && includedInTier('email', spot) && <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
        <Stack spacing={{ xs: 1 }} direction="row" alignItems="center">
          <EmailIcon />
          <Link href={`mailto:${spot.email}`}>
            {spot.email}
          </Link>
        </Stack>
      </Grid>}
      {spot.website && includedInTier('website', spot) && <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
        <Stack spacing={{ xs: 1 }} direction="row" alignItems="center">
          <PublicIcon />
          <Link href={spot.website}>
            Webbplats
          </Link>
        </Stack>
      </Grid>}
      {spot.phone_number && includedInTier('phone_number', spot) && <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
        <Stack spacing={{ xs: 1 }} direction="row" alignItems="center">
          <CallIcon />
          <Link href={`tel:${spot.phone_number}`}>
            {spot.phone_number}
          </Link>
        </Stack>
      </Grid>}
      {spot.facebook && includedInTier('facebook', spot) && <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
        <Stack spacing={{ xs: 1 }} direction="row" alignItems="center">
          <FacebookIcon />
          <Link href={spot.facebook}>
            Facebook
          </Link>
        </Stack>
      </Grid>}
      {spot.facebook && includedInTier('instagram', spot) && <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
        <Stack spacing={{ xs: 1 }} direction="row" alignItems="center">
          <InstagramIcon />
          <Link href={spot.instagram}>
            Instagram
          </Link>
        </Stack>
      </Grid>}
      {spot.tiktok && includedInTier('tiktok', spot) && <Grid item xs={12} sx={{ marginBottom: '0.5rem' }}>
        <Stack spacing={{ xs: 1 }} direction="row" alignItems="center">
          <SocialIcon tiktok width={24} height={24} padding={2}/>
          <Link href={spot.tiktok}>
            TikTok
          </Link>
        </Stack>
      </Grid>}
      <Stack sx={{ marginTop: '0.5rem' }} direction="row">
        {/* {!hideProfileButton && <Button onClick={handleClick}>Visa profil</Button>} */}
        {config.useContactForm && <Button onClick={() => setCurrentModal('ContactModal')} sx={!hideProfileButton ? { marginLeft: '8px!important' } : {}}>Kontakta</Button>}
      </Stack>
    </Grid>
  );
};

export default SpotContent;
