import { Avatar } from '@mui/material';
import Accordion from './Accordion';
import AccordionDetails from './AccordionDetails';
import AccordionSummary from './AccordionSummary';
import { includedInTier } from '../../utils';
import Spot from '../Spot/Spot';

const ListItem = props => {
  const { selectedSpot, setCenter, setSelectedSpot, spot } = props;
  const avatarUrl = spot?.profile_photo_url || spot?.user?.profile_photo_url;
  const handleChange = () => {
    setSelectedSpot(spot.id === selectedSpot ? null : spot.id);
    setCenter({
      lat: spot.location.coordinates[1],
      lng: spot.location.coordinates[0]
    });
  };

  return (
    <Accordion
      id={`ListItem_${spot.id}`}
      expanded={spot.id === selectedSpot}
      onChange={handleChange}
      sx={{ textAlign: 'left' }}
    >
      <AccordionSummary>
        {includedInTier('avatar', spot) && <Avatar sx={{ marginRight: '1rem' }} src={avatarUrl || undefined} />}
        <Spot.Header spot={spot} />
      </AccordionSummary>
      <AccordionDetails>
        <Spot.Content spot={spot} />
      </AccordionDetails>
    </Accordion>
  );
};

export default ListItem;
