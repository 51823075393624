import ContactModal from './ContactModal/ContactModal';
import useStore from '../../hooks/useStore';

const modals = {
  ContactModal
};

const ModalHandler = ({ isLargeUp, spot }) => {
  const setCurrentModal = useStore(state => state.actions.setCurrentModal);
  const currentModal = useStore(state => state.currentModal);
  const selectedSpot = useStore(state => state.selectedSpot);
  const spots = useStore(state => state.spots);
  spot = spot || spots.find(s => s.id === selectedSpot);

  const Modal = currentModal && modals[currentModal];

  if (!Modal) return null;

  return <Modal
    open={true}
    setCurrentModal={setCurrentModal}
    spot={spot}
    fullScreen={!isLargeUp}
  />;
};

export default ModalHandler;
