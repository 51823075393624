import { Link as MaterialLink, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Link = ({ children, color, direct, href, target, textVariant }) => {
  const isRouterLink = href.startsWith('/');
  const to = isRouterLink && href;

  const content = direct
    ? children
    : <Typography variant={textVariant || 'subtitle2'}>{children}</Typography>;

  return (
    <MaterialLink
      component={isRouterLink ? RouterLink : undefined}
      href={!isRouterLink && href}
      to={isRouterLink ? to : undefined}
      target={isRouterLink ? undefined : (target || '_blank')}
      color={color || '#000000'}
      sx={{
        outline: 'none'
      }}>
      {content}
    </MaterialLink>
  );
};

export default Link;
