const Instagram = props => (
  <g>
    <path style={props.fillBlack} d="M304.3,604.3L304.3,604.3c-165,0-300-135-300-300v0c0-165,135-300,300-300h0c165,0,300,135,300,300v0C604.3,469.3,469.3,604.3,304.3,604.3z" />
    <g>
      <path style={props.fillWhite} d="M222,125.9h165c53,0,96.6,43.6,96.6,96.6v164.1c0,53-43.6,96.6-96.6,96.6H222c-53,0-96.6-43.6-96.6-96.6V222.4C125.4,169.4,169,125.9,222,125.9L222,125.9z M400.7,188.3L400.7,188.3c11.1,0,20.5,9.4,20.5,20.5l0,0c0,11.1-9.4,20.5-20.5,20.5l0,0c-12,0-20.5-9.4-20.5-20.5l0,0C380.2,197.7,388.7,188.3,400.7,188.3L400.7,188.3z M304.1,207.1h0.9c53,0,97.4,44.4,97.4,97.4l0,0c0,53.9-44.4,97.4-97.4,97.4h-0.9c-53,0-96.6-43.6-96.6-97.4l0,0C207.5,251.5,251.1,207.1,304.1,207.1L304.1,207.1z M304.1,240.4h0.9c35,0,64.1,29.1,64.1,64.1l0,0c0,35.9-29.1,65-64.1,65h-0.9c-35,0-64.1-29.1-64.1-65l0,0C240,269.5,269,240.4,304.1,240.4L304.1,240.4z M222.9,156.6h163.3c36.8,0,66.7,29.9,66.7,66.7v162.4c0,36.8-29.9,66.7-66.7,66.7H222.9c-36.8,0-66.7-29.9-66.7-66.7V223.3C156.2,186.5,186.1,156.6,222.9,156.6L222.9,156.6z" />
    </g>
  </g>
);

export default Instagram;
