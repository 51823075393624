import { Box } from '@mui/material';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { memo, useCallback, useEffect, useState } from 'react';
// import basicMarker from './markers/basic.png';
// import goldMarker from './markers/gold.png';
// import selectedMarker from './markers/selected.png';
// import silverMarker from './markers/silver.png';
import wrapFinderBlackMarker from './markers/wrapfinder_black.svg';
import wrapFinderGrayMarker from './markers/wrapfinder_gray.svg';
import wrapFinderOrangeMarker from './markers/wrapfinder_orange.svg';
import wrapFinderYellowMarker from './markers/wrapfinder_yellow.svg';
import config from '../../config';
import injectSpiderfier from '../../vendor/oms.min';
import Spot from '../Spot/Spot';

const libraries = ['geometry', 'places', 'marker'];

const getMarker = (spot, selectedSpot) => {
  if (spot.id === selectedSpot) return wrapFinderBlackMarker; // basic = black, rgb(0,0,0)
  if (spot?.badge?.name?.toUpperCase() === 'GULD') return wrapFinderYellowMarker; // gold = yellow, rgb(255,209,41)
  if (spot?.badge?.name?.toUpperCase() === 'SILVER') return wrapFinderGrayMarker; // silver = gray, rgb(210,210,210)
  return wrapFinderOrangeMarker; // selected = orange, rgb(255,142,10);
  // if (spot.id === selectedSpot) return selectedMarker;
  // if (spot?.badge?.name?.toUpperCase() === 'GULD') return goldMarker;
  // if (spot?.badge?.name?.toUpperCase() === 'SILVER') return silverMarker;
  // return config.silverMarkerDefault ? silverMarker : basicMarker;
};

function debounce(func, timeout = 1000) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

const createLabel = text => text
  ? ({
    className: 'marker-label',
    fontSize: '12pt',
    fontWeight: 'bold',
    text
  })
  : '';

const emptyLabel = createLabel('');

const Map = (props) => {
  const { center, hidden, isLargeUp, map, navbarHeight, selectedSpot, setCenter, setMap, setSelectedSpot, spots } = props;
  const [oms, setOms] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.googleMapsApiKey,
    id: 'google-map-script',
    libraries
  });

  useEffect(() => {
    if (map) map.setCenter(center);
  }, [center, map]);

  const onLoad = useCallback(map => {
    setMap(map);
    const script = document.createElement('script');
    script.innerHTML = `var injectSpiderfier=${injectSpiderfier}`;
    document.head.appendChild(script);
    window.injectSpiderfier();
    const OMS = new window.OverlappingMarkerSpiderfier(map, {
      legWeight: 0,
      markersWontHide: true,
      markersWontMove: true
    });
    setOms(OMS);
  }, [setMap]);

  const onUnmount = useCallback(map => {
    setMap(null);
  }, [setMap]);

  const onClickMarker = id => function (e) {
    const markerId = id || this.title || e.domEvent.target.title;

    setCenter({
      lat: e.latLng.lat(),
      lng: e.latLng.lng()
    });

    setSelectedSpot(markerId);
    setTimeout(() => {
      const element = document.querySelector(`#ListItem_${markerId}`);

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }, 500);
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      <GoogleMap
        mapContainerStyle={{
          ...(isLargeUp
            ? {
              height: '100%'
            }
            : {
              height: `calc(80vh - ${navbarHeight}px)`,
              minHeight: '-webkit-fill-available'
            }),
          ...(hidden && {
            display: 'none'
          }),
          width: '100%'

        }}
        center={center}
        options={{
          fullscreenControl: false,
          keyboardShortcuts: false,
          mapTypeControl: false,
          panControl: false,
          rotateControl: false,
          streetViewControl: false,
          tilt: 0
        }}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {oms && spots.map(spot => {
          const position = new window.google.maps.LatLng(spot.location.coordinates[1], spot.location.coordinates[0]);
          const onSpiderClick = onClickMarker(spot.id);
          const onClick = (marker) => e => {
            const markersInSpider = [marker, ...oms.markersNearMarker(marker)];
            markersInSpider.forEach(m => m.setLabel(emptyLabel));
          };
          const onSpiderFormat = marker => {
            return debounce((status) => {
              const isSpiderfied = status === window.OverlappingMarkerSpiderfier.markerStatus.SPIDERFIED;
              const isSpiderfiable = status === window.OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE;
              let markersInSpider = [marker, ...oms.markersNearMarker(marker)];

              if (isSpiderfied) {
                marker.setLabel(emptyLabel);
                return;
              }

              if (!isSpiderfiable) {
                marker.setLabel(emptyLabel);
              } else {
                marker.setLabel(emptyLabel);
                markersInSpider = [marker, ...oms.markersNearMarker(marker)];

                if (markersInSpider.length <= 1) return;

                const highestLatitudeMarkerInSpider = markersInSpider
                  .reduce((largest, current) => {
                    const currentSpot = spots.find(s => s.id === current.title);
                    const [, currentLat] = currentSpot.location.coordinates;
                    const largestSpot = spots.find(s => s.id === largest.title);
                    const [, largestLat] = largestSpot.location.coordinates;
                    return (currentLat > largestLat ? current : largest);
                  }, markersInSpider[0]);

                const label = createLabel(String(markersInSpider.length));
                highestLatitudeMarkerInSpider.setLabel(label);

                const otherMarkers = markersInSpider.filter(m => m.title !== highestLatitudeMarkerInSpider.title);

                otherMarkers.forEach(m => {
                  m.setLabel(emptyLabel);
                });
              }
            });
          };

          return (
            <Marker
              label={emptyLabel}
              key={spot.id}
              position={position}
              onUnmount={marker => {
                if (!marker) return;
                window.google.maps.event.clearListeners(marker, 'click');
                window.google.maps.event.clearListeners(marker, 'spider_click');
                window.google.maps.event.clearListeners(marker, 'spider_format');
              }}
              onLoad={marker => {
                oms.addMarker(marker);

                window.google.maps.event.addListener(
                  marker,
                  'click',
                  onClick(marker)
                );

                window.google.maps.event.addListener(
                  marker,
                  'spider_click',
                  onSpiderClick
                );

                window.google.maps.event.addListener(
                  marker,
                  'spider_format',
                  onSpiderFormat(marker)
                );
              }}
              title={spot.id}
              icon={{
                // labelOrigin: new window.google.maps.Point(13, -14),
                scaledSize: new window.google.maps.Size(35, 45),
                url: getMarker(spot, selectedSpot)
              }}
              zIndex={selectedSpot === spot.id ? 10000 : 1000}
            >
              {selectedSpot === spot.id && <InfoWindow position={position} onCloseClick={() => setSelectedSpot(null)}>
                <Box sx={{
                  background: '#ffffff',
                  textAlign: 'left'
                }}>
                  <Spot.Header spot={spot} />
                  <Box sx={{ height: '1rem' }} />
                  <Spot.Content spot={spot} />
                </Box>
              </InfoWindow>}
            </Marker>
          );
        })}

      </GoogleMap>
    </>
  );
};

export default memo(Map);
