import { TextField } from '@mui/material';

const FormFields = ({ errors, formData, formGroup, handleChange }) => {
  return formGroup.map(field => {
    const value = formData[field.name];
    return (
      <TextField
        key={field.name + field.type + field.label}
        color="dark"
        margin="dense"
        variant="filled"
        fullWidth={field.fullWidth || true}
        id={field.name}
        onChange={handleChange(field.name)}
        value={value}
        {...field}
        error={Boolean(errors[field.name])}
        helperText={errors[field.name]}
      />
    );
  });
};

export default FormFields;
