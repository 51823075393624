import './App.css';
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import deepEqual from 'fast-deep-equal';
import omit from 'just-omit';
import { useEffect, useRef, useState } from 'react';
import useGeolocation from 'react-hook-geolocation';
import { useLocation, useSearchParams } from 'react-router-dom';
import AppFooter from './AppFooter';
import MobileApp from './MobileApp';
import api from '../../api';
import config from '../../config';
import useStore from '../../hooks/useStore';
import { getInitialCenter, isDefaultLocation, normalizePlaceholder, paramsToObject } from '../../utils';
import Button from '../Button/Button';
import Filters from '../Filters/Filters';
import List from '../List/List';
import Map from '../Map/Map';
import ModalHandler from '../ModalHandler/ModalHandler';
import Search from '../Search/Search';

const titleCase = (s) => {
  return s.replace(/^-*(.)|-+(.)/g, (s, c, d) => {
    return c
      ? c.toUpperCase()
      : ' ' + d.toUpperCase();
  });
};

const getLocationFilters = (location, filters) => {
  if (
    !location ||
    !location.pathname ||
    !location.pathname.match(/^\/([\w-]+)/) ||
    location.pathname.match(/^\/([\w-]+)/).length < 2 ||
    location.pathname.match(/^\/([\w-]+)/)[1] === 'hitta'
  ) {
    return {};
  }

  const [, service] = location.pathname.match(/^\/([\w-]+)/);

  if (filters && filters.Tjänster && filters.Tjänster[0] && filters.Tjänster[0] === titleCase(service)) {
    return {};
  }

  return {
    Tjänster: [titleCase(service)]
  };
};

const App = props => {
  const theme = useTheme();
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'));

  const geolocation = useGeolocation();
  const [params, setParams] = useSearchParams();
  const location = useLocation();

  const [initialGeolocation, setInitialGeolocation] = useState(null);
  const [map, setMap] = useState(null);
  const [categories, setCategories] = useState({});
  const [filters, setFilters] = useState({ ...paramsToObject(params) });
  const [navbarHeight, setNavbarHeight] = useState(config.defaultNavbarHeight);

  const initialCenter = getInitialCenter(filters);
  const [center, setCenter] = useState(initialCenter);
  const [searchLocation, setSearchLocation] = useState(initialCenter);

  const setSpots = useStore(state => state.actions.setSpots);
  const setSelectedSpot = useStore(state => state.actions.setSelectedSpot);
  const spots = useStore(state => state.spots);
  const selectedSpot = useStore(state => state.selectedSpot);

  const searchQuery = useStore(state => state.searchQuery);
  const setSearchQuery = useStore(state => state.actions.setSearchQuery);
  const navbarRef = useRef();

  const omitParams = paramsToOmit => {
    const nextFilters = omit(filters, paramsToOmit);
    setParams(nextFilters);
    setFilters(nextFilters);
    setSearchLocation(nextFilters);
  };

  const setFiltersSpread = patch => {
    const nextFilters = ({
      ...filters,
      ...patch
    });

    setParams(nextFilters);
    setFilters(nextFilters);
  };

  const setSearchLocationSpread = location => {
    const handledLocation = Object.entries(location)
      .reduce((acc, [key, value]) => {
        if (value) {
          return {
            ...acc,
            [key]: value
          };
        }
        return acc;
      }, {});

    setFiltersSpread(handledLocation);
    setSearchLocation(handledLocation);
  };

  const handleSearchChange = (type) => (e) => {
    const q = e.target.value;
    setSearchQuery({ [type]: q });

    if (!q) {
      if (type === 'place') {
        setSearchQuery({ placeholder: '' });
        omitParams(['lat', 'lng', 'q']);
      }
      if (type === 'name') {
        omitParams(['name']);
      }
      return;
    }

    if (type !== 'place') return new Promise(resolve => resolve(q));

    const service = new window.google.maps.places.PlacesService(map);

    return new Promise(resolve => {
      service.textSearch({
        language: 'sv',
        query: q,
        region: 'se',
        type: 'locality'
      }, results => {
        if (!results || results.length === 0) {
          setSearchQuery({ placeholder: '' });
          resolve(null);
          return;
        };

        setSearchQuery({ placeholder: results[0].name });

        resolve(results);
      });
    });
  };

  const handleSearchSubmit = async e => {
    const service = new window.google.maps.places.PlacesService(map);

    const getCoordinates = () => new Promise(resolve => {
      if (!searchQuery.place) return resolve(null);

      service.textSearch({
        query: searchQuery.place,
        type: 'locality'
      }, results => {
        if (!results || results.length === 0) return resolve(null);
        resolve({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        });
      });
    });

    const coordinates = await getCoordinates();

    setSearchLocationSpread({
      ...(searchQuery.name && {
        name: searchQuery.name
      }),
      ...(searchQuery.place && {
        q: searchQuery.place
      }),
      ...(coordinates && coordinates)
    });

    if (coordinates) {
      setCenter(coordinates);
      map.setZoom(12);
    }
  };

  useEffect(() => {
    const paramsObj = paramsToObject(params);

    setSearchQuery({
      ...(paramsObj.name && {
        name: paramsObj.name[0] || ''
      }),
      ...(paramsObj.q && {
        place: paramsObj.q[0] || ''
      })
    });
  }, []);

  useEffect(() => {
    if (initialGeolocation) return;
    if (geolocation.latitude && geolocation.longitude) {
      setInitialGeolocation(geolocation);
    }
  }, [geolocation, initialGeolocation]);

  useEffect(() => {
    const payload = {
      ...(!isDefaultLocation(searchLocation) && searchLocation.lat && searchLocation.lng && {
        lat: searchLocation.lat,
        lng: searchLocation.lng
      }),
      ...filters,
      ...getLocationFilters(location, filters)
    };

    if (!deepEqual(payload, filters)) {
      return setFiltersSpread(payload);
    }

    api.spots
      .search(config.disconnectedPlaceSearch ? omit(payload, ['lng', 'lat']) : payload)
      .then(spots => {
        if (!spots) {
          console.warn('No locations found');
          setSpots([]);
          return;
        }
        setSpots(spots);
      });
  }, [searchLocation, filters, setSpots, location]);

  useEffect(() => {
    api.categories
      .getAll()
      .then(categories => {
        setCategories(categories);
      });
  }, []);

  useEffect(() => {
    if (!isLargeUp) return;
    setNavbarHeight(navbarRef?.current?.clientHeight || config.defaultNavbarHeight);
  }, [navbarRef?.current?.clientHeight, isLargeUp]);

  useEffect(() => {
    const exactSpot = spots.find(spot => Number(spot.location.coordinates[0]) === Number(filters.lng) && Number(spot.location.coordinates[1]) === Number(filters.lat));
    if (!exactSpot) return;
    setSelectedSpot(exactSpot.id);
  }, [filters, spots, setSelectedSpot]);

  const modalHandler = <ModalHandler isLargeUp={isLargeUp} />;
  const hideMap = Boolean(params.get('hideMap'));

  if (!isLargeUp) {
    return (
      <MobileApp
        theme={theme}
        navbarRef={navbarRef}
        isLargeUp={isLargeUp}
        navbarHeight={navbarHeight}
        spots={spots}
        geolocation={geolocation}
        selectedSpot={selectedSpot}
        setSelectedSpot={setSelectedSpot}
        setCenter={setCenter}
        center={center}
        map={map}
        setMap={setMap}
        categories={categories}
        filters={filters}
        setFiltersSpread={setFiltersSpread}
        setParams={setParams}
        setSearchLocationSpread={setSearchLocationSpread}
        searchLocation={searchLocation}
        modalHandler={modalHandler}
        omitParams={omitParams}
        hideMap={hideMap}
        handleSearchChange={handleSearchChange}
        handleSearchSubmit={handleSearchSubmit}
      />
    );
  }

  return (
    <div className="App" style={{ position: 'relative' }}>
      {!hideMap && <Grid container ref={navbarRef} spacing={0} sx={{
        ...(isLargeUp && { minHeight: config.defaultNavbarHeight }),
        backgroundColor: config.colors.secondary
      }}>
        <Grid item lg={3} xs={12} textAlign="left" sx={{
          position: 'relative'
        }}>
          <Search
            type='place'
            map={map}
            center={center}
            setCenter={setCenter}
            setSearchLocation={setSearchLocationSpread}
            searchLocation={searchLocation}
            isLargeUp={isLargeUp}
            navbarHeight={navbarHeight}
            omitParams={omitParams}
            disabled={hideMap}
            handleSearchChange={handleSearchChange}
          />
          {config.useNameSearch && <Search
            type='name'
            map={map}
            center={center}
            setCenter={setCenter}
            setSearchLocation={setSearchLocationSpread}
            searchLocation={searchLocation}
            isLargeUp={isLargeUp}
            navbarHeight={navbarHeight}
            omitParams={omitParams}
            disabled={hideMap}
            handleSearchChange={handleSearchChange}
          />}
        </Grid>
        <Grid item lg={1} xs={12} textAlign="left">
          <Button fullWidth
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(255,160,30)!important'
              },
              backgroundColor: config.colors.primary,
              height: config.defaultNavbarHeight
            }}
            onClick={handleSearchSubmit}
          >Sök</Button>
        </Grid>
        <Grid item lg={8} xs={12} textAlign="left">
          <Filters
            categories={categories}
            filters={filters}
            setFilters={setFiltersSpread}
            setParams={setParams}
            isLargeUp={isLargeUp}
            navbarHeight={navbarHeight}
            disabled={hideMap}
            map={map}
          />
        </Grid>
      </Grid>}
      <Grid container spacing={0}>
        <Grid key='list_grid' item lg={hideMap ? 12 : 4} xs={12} sx={{
          ...(isLargeUp && {
            height: `calc(100vh - ${navbarHeight}px)`,
            overflow: 'scroll',
            ...(hideMap && {
              height: '100vh'
            })
          })
        }}>
          <List
            spots={spots}
            geolocation={geolocation}
            selectedSpot={selectedSpot}
            setSelectedSpot={setSelectedSpot}
            setCenter={setCenter}
            center={center}
            hideMap={hideMap}
          />
        </Grid>
        <Grid key='map_grid' item lg={8} xs={12}>
          <Map
            spots={spots}
            selectedSpot={selectedSpot}
            setSelectedSpot={setSelectedSpot}
            setCenter={setCenter}
            center={center}
            map={map}
            setMap={setMap}
            isLargeUp={isLargeUp}
            navbarHeight={navbarHeight}
            hidden={hideMap}
          />
        </Grid>
      </Grid>
      <AppFooter />
      {modalHandler}
    </div>
  );
};

export default App;
