import { Box, CircularProgress, DialogContentText } from '@mui/material';

const ContactText = ({ fullScreen, spot, status, submitted }) => {
  const statusText = status === 'sent'
    ? 'Tack för ditt meddelande!'
    : 'Kunde inte skicka meddelande, försök igen senare';

  if (submitted) {
    return (
      <>
        {status
          ? <DialogContentText>{statusText}</DialogContentText>
          : (
            <Box sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              ...(fullScreen
                ? {
                  height: '100%'
                }
                : {
                  padding: '5rem'
                })
            }}>
              <CircularProgress disableShrink size={60} />
              <DialogContentText sx={{
                paddingTop: '2rem'
              }}>
                Skickar meddelande
              </DialogContentText>
            </Box>
          )
        }
      </>
    );
  }

  return (
    <DialogContentText>
      Fyll i formuläret för att skicka ett meddelande till {spot.name}
    </DialogContentText>
  );
};

export default ContactText;
