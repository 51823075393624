import { Button as MaterialButton, ToggleButton as MaterialToggleButton } from '@mui/material';

const Button = props => {
  const Component = props.toggle ? MaterialToggleButton : MaterialButton;

  const childProps = {
    className: props.className,
    color: props.color || 'dark',
    disabled: props.disabled,
    fullWidth: props.fullWidth,
    onChange: props.onChange,
    onClick: props.onClick,
    selected: props.selected,
    size: props.size,
    sx: {
      boxShadow: 'none',
      ...(props.borderRadius === false && {
        borderRadius: 0
      }),
      ...props.sx
    },
    value: props.value,
    variant: props.variant || 'contained',
    ...(!props.toggle && {
      disableElevation: true
    })
  };

  return (
    <Component
      {...childProps}
    >
      {props.children}
    </Component>
  );
};

export default Button;
