import Facebook from './Facebook';
import Instagram from './Instagram';
import Tiktok from './Tiktok';

const getIcon = props => {
  if (props.instagram) return Instagram;
  if (props.tiktok) return Tiktok;
  if (props.facebook) return Facebook;
};

const SocialIcon = props => {
  const { height, padding, width } = props;
  const fillBlack = { clipRule: 'evenodd', fill: '#000000', fillRule: 'evenodd' };
  const fillWhite = { clipRule: 'evenodd', fill: '#FFFFFF', fillRule: 'evenodd' };

  const Icon = getIcon(props);

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 608.6 608.6"
      xmlSpace="preserve"
      style={(width || height) && {
        ...(width && { width }),
        ...(height && { height }),
        ...(padding && { padding })
      }}
    >
      <Icon
        fillBlack={fillBlack}
        fillWhite={fillWhite}
      />
    </svg>
  );
};

export default SocialIcon;
