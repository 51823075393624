import { Box } from '@mui/material';
import { useState } from 'react';
import MobileNav from './MobileNav';
import config from '../../config';
import Button from '../Button/Button';
import Filters from '../Filters/Filters';
import List from '../List/List';
import Map from '../Map/Map';
import Search from '../Search/Search';

const MobileApp = props => {
  const [view, setView] = useState('list');

  const handleSearchClick = async e => {
    props.handleSearchSubmit();

    setView('list');
  };

  return (
    <div className="App" style={{ minHeight: 600, overflow: 'hidden', position: 'relative' }}>
      <MobileNav
        theme={props.theme}
        navbarRef={props.navbarRef}
        navbarHeight={props.navbarHeight}
        setView={setView}
        view={view}
        disabled={props.hideMap}
      />
      {view === 'list' && (
        <List
          spots={props.spots}
          geolocation={props.geolocation}
          selectedSpot={props.selectedSpot}
          setSelectedSpot={props.setSelectedSpot}
          setCenter={props.setCenter}
          center={props.center}
          isLargeUp={props.isLargeUp}
        />
      )}
      {view === 'filters' && (
        <>
          <Search
            type='place'
            map={props.map}
            center={props.center}
            setCenter={props.setCenter}
            setSearchLocation={props.setSearchLocationSpread}
            searchLocation={props.searchLocation}
            navbarHeight={props.navbarHeight}
            omitParams={props.omitParams}
            handleSearchChange={props.handleSearchChange}
            handleSearchSubmit={props.handleSearchSubmit}
          />
          {config.useNameSearch && <Search
            type='name'
            map={props.map}
            center={props.center}
            setCenter={props.setCenter}
            setSearchLocation={props.setSearchLocationSpread}
            searchLocation={props.searchLocation}
            navbarHeight={props.navbarHeight}
            omitParams={props.omitParams}
            handleSearchChange={props.handleSearchChange}
            handleSearchSubmit={props.handleSearchSubmit}
          />}
          <Box sx={{ textAlign: 'left' }}>
            <Button onClick={handleSearchClick} size="large" sx={{
              margin: '1rem'
            }}>Sök</Button>
          </Box>
          <Filters
            categories={props.categories}
            filters={props.filters}
            setFilters={props.setFiltersSpread}
            setParams={props.setParams}
            isLargeUp={props.isLargeUp}
            navbarHeight={props.navbarHeight}
          />
        </>

      )}
      <Map
        hidden={view !== 'map' || props.hideMap}
        spots={props.spots}
        selectedSpot={props.selectedSpot}
        setSelectedSpot={props.setSelectedSpot}
        setCenter={props.setCenter}
        center={props.center}
        map={props.map}
        setMap={props.setMap}
        isLargeUp={props.isLargeUp}
        navbarHeight={props.navbarHeight}
      />
      {props.modalHandler}
    </div>
  );
};

export default MobileApp;
